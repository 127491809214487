import { getAuth } from "firebase/auth";
import { apiConfig } from "../config";
import { APIError, APIErrorResponse } from "../types/api-response";

export async function query<T>(resource: string): Promise<T | null> {
  const response = await authenticatedFetch(resource, "GET");

  if (response.status === 204) {
    return null;
  }
  const content = await response.json();

  if (response.status === 200) {
    return content as T;
  }

  throw new APIError(content as APIErrorResponse);
}

export async function remove<T>(resource: string): Promise<T> {
  const response = await authenticatedFetch(resource, "DELETE");

  if (response.status === 204) {
    return null;
  }
  const content = await response.json();

  if (response.status === 200) {
    return content as T;
  }

  throw new APIError(content as APIErrorResponse);
}

export async function queryFile(resource: string): Promise<Blob | null> {
  const response = await authenticatedFetch(resource, "GET");

  if (response.status === 204) {
    return null;
  }

  return await response.blob();
}

export async function update<T>(resource: string, body?: T) {
  const response = await authenticatedFetch(resource, "PUT", body);
  const content = await response.json();

  if (response.status === 200) {
    return content;
  }

  throw new APIError(content as APIErrorResponse);
}

export async function post<T>(resource: string, body?: T) {
  const response = await authenticatedFetch(`${resource}`, "POST", body);
  const content = await response.json();

  if (response.status === 200) {
    return content;
  }

  throw new APIError(content as APIErrorResponse);
}

export async function create<T, U = any>(
  resource: string,
  body: U
): Promise<T> {
  const response = await authenticatedFetch(resource, "POST", body);
  const content = await response.json();

  if ([200, 201].includes(response.status)) {
    return content;
  }

  throw new APIError(content as APIErrorResponse);
}

export async function authenticatedFetch(
  url: string,
  method: string,
  body?: any
): Promise<Response> {
  const token = await getAuth().currentUser.getIdToken();
  const headers = body
    ? {
        authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8"
      }
    : { authorization: `Bearer ${token}` };
  return fetch(`${apiConfig.baseUrl}/${url}`, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined
  });
}

export async function put<T>(resource: string, body?: T) {
  const response = await authenticatedFetch(`${resource}`, "PUT", body);
  const content = await response.json();

  if (response.status === 200) {
    return content;
  }

  throw new APIError(content as APIErrorResponse);
}
