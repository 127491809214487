import React, { useRef, useState } from "react";
import type { FC } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from "@material-ui/core";
import useAuthentication, { useUser } from "./../utils/util-use-auth";
import { BASE_URL } from "./../constants";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

const Account: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef<any>(null);
  const { avatar, logout } = useAuthentication();
  const { user, role } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      history.push(BASE_URL);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to logout", {
        variant: "error"
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        // @ts-ignore
        ref={ref}
      >
        <Avatar alt="User" className={classes.avatar} src={avatar} />
        <Hidden smDown>
          <div style={{ textAlign: "left" }}>
            <Typography variant="h5" color="inherit" display="block">
              {user.name}
            </Typography>
            <Typography
              variant="caption"
              color="inherit"
              display="block"
              style={{ textTransform: "capitalize", opacity: 0.8 }}
            >
              {role.name}
            </Typography>
          </div>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default Account;
