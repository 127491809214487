import {
  Box,
  Drawer,
  Hidden,
  List,
  makeStyles,
  Theme
} from "@material-ui/core";
import { DirectionsCar as VehicleIcon } from "@mui/icons-material";
import React, { FC, useEffect } from "react";
import {
  Briefcase as BusinessIcon,
  CreditCard as InvoiceIcon,
  Gift as PromocodeIcon,
  Map as TripIcon,
  Users as UsersIcon,
  UserX as UsersWatchlistIcon,
  BarChart2 as AnalyticsIcon
} from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useLocation } from "react-router-dom";
import Logo from "./../components/logo";
import { BASE_URL } from "./../constants";
import { Permission } from "./../types/role";
import { useUser } from "./../utils/util-use-auth";
import usePermission from "./../utils/util-use-permission";
import NavItem, { NavItemProps } from "./nav-item";

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)"
  },
  avatar: {
    width: 64,
    height: 64
  },
  logo: {
    color: theme.palette.primary.main
  }
}));

type NavItemWithPermissionProps = NavItemProps & {
  permission: Permission;
};

const managementNavigationItems: NavItemWithPermissionProps[] = [
  {
    depth: 0,
    title: "Vehicles",
    icon: VehicleIcon,
    href: `${BASE_URL}/vehicles`,
    permission: "viewVehicle"
  },
  {
    depth: 0,
    title: "Users",
    icon: UsersIcon,
    href: `${BASE_URL}/users`,
    permission: "viewUser"
  },
  {
    depth: 0,
    title: "Trips",
    icon: TripIcon,
    href: `${BASE_URL}/trips`,
    permission: "viewTrips"
  },
  {
    depth: 0,
    title: "Businesses",
    icon: BusinessIcon,
    href: `${BASE_URL}/business`,
    permission: "viewBusiness"
  },
  {
    depth: 0,
    title: "Invoices",
    icon: InvoiceIcon,
    href: `${BASE_URL}/invoices`,
    permission: "viewInvoice"
  },
  {
    depth: 0,
    title: "Promocodes",
    icon: PromocodeIcon,
    href: `${BASE_URL}/promocodes`,
    permission: "viewPromocode"
  },
  {
    depth: 0,
    title: "Users Watchlist",
    icon: UsersWatchlistIcon,
    href: `${BASE_URL}/watchlist`,
    permission: "viewUser"
  },
  {
    depth: 0,
    title: "Analytics",
    icon: AnalyticsIcon,
    href: `${BASE_URL}/analytics`,
    permission: "viewVehicle"
  }
];

const NavItemWithPermission: FC<NavItemWithPermissionProps> = ({
  permission,
  ...rest
}) => {
  const isElligible = usePermission(permission);
  return isElligible ? <NavItem {...rest}></NavItem> : null;
};

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { role } = useUser();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <Link to={BASE_URL}>
              <Logo className={classes.logo} />
            </Link>
          </Box>
        </Hidden>
        {role.manageOwnBusiness || role.viewSingleTrip ? null : (
          <Box p={2}>
            <List>
              {managementNavigationItems.map((item, index) => (
                <NavItemWithPermission key={index} {...item} />
              ))}
            </List>
          </Box>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
