import React, { FC, useState } from "react";

import { makeStyles } from "@material-ui/core";

import type { Theme } from "./../utils/util-theme";
import NavBar from "./nav-bar";
import TopBarDashboard from "./top-bar-dashboard";
import { useUser } from "../utils/util-use-auth";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%"
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden"
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto"
  }
}));

const DashboardLayout: FC = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const { role } = useUser();

  return (
    <div className={classes.root}>
      <TopBarDashboard onMobileNavOpen={() => setMobileNavOpen(true)} />
      {role.manageOwnBusiness || role.viewSingleTrip ? null : (
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
      )}
      <div
        className={classes.wrapper}
        style={
          role.manageOwnBusiness || role.viewSingleTrip
            ? { paddingLeft: 0 }
            : {}
        }
      >
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
