import React, { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import { create } from "jss";
import rtl from "jss-rtl";
import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";
import { jssPreset, StylesProvider, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import GlobalStyles from "./components/global-styles";
import ScrollReset from "./components/scroll-reset";
import { AuthProvider } from "./states/state-auth";
import useSettings from "./utils/util-use-settings";
import { createTheme } from "./utils/util-theme";
import routes, { renderRoutes } from "./routes";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const App: FC = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider dense maxSnack={3}>
            <BrowserRouter>
              <AuthProvider>
                <GlobalStyles />
                <ScrollReset />
                {renderRoutes(routes)}
              </AuthProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
