import React, { FC } from "react";
import { Redirect } from "react-router-dom";
import { BASE_URL } from "./../constants";
import useAuthentication from "./util-use-auth";

const AuthGuard: FC = ({ children }) => {
  const { isAuthenticated } = useAuthentication();

  if (!isAuthenticated) {
    return <Redirect to={`${BASE_URL}/login`} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
