import React, { FC } from "react";
import { Redirect } from "react-router-dom";

import { BASE_URL } from "./../constants";
import useAuthentication from "./util-use-auth";

const GuestGuard: FC = ({ children }) => {
  const { isAuthenticated } = useAuthentication();

  if (isAuthenticated) {
    return <Redirect to={BASE_URL} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
