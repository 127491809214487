import React, { useEffect, useState } from "react";
import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSnackbar } from "notistack";
import {
  Search as SearchIcon,
  XCircle as XIcon,
  Briefcase as BusinessIcon
} from "react-feather";
import {
  DirectionsCar as CarIcon,
  Moped as MopedIcon,
  ElectricScooter as ScooterIcon,
  LocalShipping as VanIcon,
  DirectionsCarOutlined as CarOutlinedIcon,
  MopedOutlined as MopedOutlinedIcon,
  ElectricScooterOutlined as ScooterOutlinedIcon,
  LocalShippingOutlined as VanOutlinedIcon,
  Person as UserIcon,
  PersonOutlined as UserOutlinedIcon
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  colors,
  Chip
} from "@material-ui/core";
import { BASE_URL } from "./../constants";
import { query } from "./../utils/util-query";
import {
  VehicleStatus as VehicleStatusType,
  VehicleType
} from "../types/vehicle";
import { VehicleStatus } from "../pages/components/vehicle-status";
import { vehicleStatusColors } from "../utils/util-theme";
import { ListItemText } from "@mui/material";

type Result = {
  uuid: string;
  title: string;
  subTitle?: string;
  type: ResultType;
  status?: VehicleStatusType;
  enabled?: boolean;
};

type ResultType = "user" | VehicleType | "business";

const useStyles = makeStyles(() => ({
  drawer: {
    width: 500,
    maxWidth: "100%"
  },
  searchItem: {
    textDecoration: "none",
    display: "flex",
    alignContent: "center",
    "&:hover": {
      textDecoration: "none"
    }
  }
}));

const Search: FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState<string>("");
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<Result[]>([]);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
    setValue("");
  };

  const handleSearch = async (): Promise<void> => {
    try {
      if (value.length > 2) {
        setLoading(true);
        setResults(await query(`search/${encodeURIComponent(value)}`));
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Something went wrong", {
        variant: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => handleSearch(), 500);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (results.length === 0 && value.length > 2) {
      enqueueSnackbar(`We couldn't find a match for "${value}"`, {
        variant: "error"
      });
    }
    // eslint-disable-next-line
  }, [results]);

  return (
    <>
      <Tooltip title="Search">
        <IconButton color="inherit" onClick={handleOpen}>
          <SvgIcon fontSize="small">
            <SearchIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={isOpen}
        variant="temporary"
      >
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={3}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4" color="textPrimary">
                Search
              </Typography>
              <IconButton onClick={handleClose}>
                <SvgIcon fontSize="small">
                  <XIcon />
                </SvgIcon>
              </IconButton>
            </Box>
            <Box mt={2}>
              <TextField
                fullWidth
                autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                onChange={(event) => setValue(event.target.value)}
                placeholder="Search people, businesses &amp; vehicles"
                value={value}
                variant="outlined"
              />
            </Box>
            <List>
              {isLoading ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {Boolean(value) && results.length === 0 && (
                    <ListItem>
                      <Typography variant="caption" color="textSecondary">
                        No results for "{value}"
                      </Typography>
                    </ListItem>
                  )}
                  {results.map((result, i) => (
                    <ListItem
                      key={i}
                      button
                      component={RouterLink}
                      to={resultToLink(result)}
                      onClick={handleClose}
                      className={classes.searchItem}
                      dense
                    >
                      {resultToListItemContent(result)}
                    </ListItem>
                  ))}
                </>
              )}
            </List>
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </>
  );
};

function resultToIcon(result: Result): JSX.Element {
  switch (result.type) {
    case "user":
      if (result.enabled) {
        return <UserIcon />;
      } else {
        return <UserOutlinedIcon />;
      }
    case "business":
      return <BusinessIcon />;
    case "car":
      if (result.enabled) {
        return <CarIcon />;
      } else {
        return <CarOutlinedIcon />;
      }
    case "scooter":
      if (result.enabled) {
        return <ScooterIcon />;
      } else {
        return <ScooterOutlinedIcon />;
      }
    case "moped":
      if (result.enabled) {
        return <MopedIcon />;
      } else {
        return <MopedOutlinedIcon />;
      }
    case "van":
      if (result.enabled) {
        return <VanIcon />;
      } else {
        return <VanOutlinedIcon />;
      }
  }
}

function resultToLink(result: Result): string {
  switch (result.type) {
    case "user":
      return `${BASE_URL}/users/${result.uuid}`;
    case "business":
      return `${BASE_URL}/business/${result.uuid}`;
    case "car":
    case "scooter":
    case "moped":
    case "van":
      return `${BASE_URL}/vehicles/${result.uuid}`;
  }
}

function resultToListItemContent(result: Result): JSX.Element {
  switch (result.type) {
    case "user":
      const userColorStyle = {
        color: result.enabled ? colors.green[600] : colors.red[600]
      };
      return (
        <>
          <ListItemIcon style={userColorStyle}>
            {resultToIcon(result)}
          </ListItemIcon>
          <ListItemText
            style={userColorStyle}
            primary={result.title}
            secondary={result.subTitle}
          />
        </>
      );
    case "business":
      return (
        <>
          <ListItemIcon style={{ color: colors.blueGrey[900] }}>
            {resultToIcon(result)}
          </ListItemIcon>
          <ListItemText primary={result.title} secondary={result.subTitle} />
        </>
      );
    case "car":
    case "scooter":
    case "moped":
    case "van":
      const vehicleColorStyle = {
        color:
          result.enabled && result.status
            ? vehicleStatusColors[result.status][700]
            : colors.blueGrey[700]
      };
      return (
        <>
          <ListItemIcon style={vehicleColorStyle}>
            {resultToIcon(result)}
          </ListItemIcon>
          <ListItemText
            style={vehicleColorStyle}
            primary={
              <>
                {result.title}{" "}
                {result.enabled && result.status && (
                  <VehicleStatus status={result.status} />
                )}
                {!result.enabled && (
                  <Chip
                    label="OOS"
                    size="small"
                    style={{
                      color: colors.blueGrey[800],
                      backgroundColor: colors.blueGrey[50]
                    }}
                  />
                )}
              </>
            }
            secondary={result.subTitle}
          />
        </>
      );
  }
}

export default Search;
