import React, { Fragment, lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DashboardLayout from "./components/layout-dashboard";
import LoadingScreen from "./components/loading-screen";
import GuestGuard from "./utils/util-guest-guard";
import AuthGuard from "./utils/util-auth-guard";
import { BASE_URL } from "./constants";
import LayoutMain from "./components/layout-main";
import SinglePageGuard from "./utils/util-single-page-guard";

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: `${BASE_URL}/404`,
    component: lazy(() => import("./pages/page-not-found"))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: `${BASE_URL}/login`,
    component: lazy(() => import("./pages/page-login"))
  },
  {
    path: `${BASE_URL}`,
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        guard: SinglePageGuard,
        path: `${BASE_URL}/analytics`,
        component: lazy(() => import("./pages/page-analytics"))
      },
      {
        exact: true,
        path: `${BASE_URL}/trips`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-trips-list"))
      },
      {
        exact: true,
        path: `${BASE_URL}/trips/:id/`,
        component: lazy(() => import("./pages/page-trips-detail"))
      },
      {
        exact: true,
        path: `${BASE_URL}/business`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-business-list"))
      },
      {
        exact: true,
        path: `${BASE_URL}/business/create`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-business-create"))
      },
      {
        exact: true,
        path: `${BASE_URL}/business/:id`,
        component: lazy(() => import("./pages/page-business-detail"))
      },
      {
        exact: true,
        path: `${BASE_URL}/business/:id/edit`,
        component: lazy(() => import("./pages/page-entity-edit"))
      },
      {
        exact: true,
        path: `${BASE_URL}/business/:id/payment`,
        component: lazy(() => import("./pages/page-business-payment"))
      },
      {
        exact: true,
        path: `${BASE_URL}/users`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-user-list"))
      },
      {
        exact: true,
        path: `${BASE_URL}/users/create`,
        component: lazy(() => import("./pages/page-users-create"))
      },

      {
        exact: true,
        path: `${BASE_URL}/vehicles/:id/issue/create`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-issue-create"))
      },
      {
        exact: true,
        path: `${BASE_URL}/users/:id`,
        component: lazy(() => import("./pages/page-user-detail"))
      },
      {
        exact: true,
        path: `${BASE_URL}/users/:id/edit`,
        component: lazy(() => import("./pages/page-user-edit"))
      },
      {
        exact: true,
        path: `${BASE_URL}/invoices`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-invoice-list"))
      },
      {
        exact: true,
        path: `${BASE_URL}/invoices/:id`,
        component: lazy(() => import("./pages/page-invoice-detail"))
      },
      {
        exact: true,
        path: `${BASE_URL}/invoices/:id/credits/note/create`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-credit-note-create"))
      },
      {
        exact: true,
        path: `${BASE_URL}/vehicles`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-vehicles"))
      },
      {
        exact: true,
        path: `${BASE_URL}/vehicles/:id`,
        component: lazy(() => import("./pages/page-vehicle-detail"))
      },
      {
        exact: true,
        path: `${BASE_URL}/issues/:id`,
        component: lazy(() => import("./pages/page-issue-detail"))
      },
      {
        exact: true,
        path: `${BASE_URL}/products/:userId/charge`,
        component: lazy(() => import("./pages/page-charge-product"))
      },
      {
        exact: true,
        path: `${BASE_URL}/promocodes`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-promocode-list"))
      },
      {
        exact: true,
        path: `${BASE_URL}/promocodes/create`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-promocode-create"))
      },
      {
        exact: true,
        path: `${BASE_URL}/promocodes/:id`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-promocode-edit"))
      },
      {
        exact: true,
        path: `${BASE_URL}/watchlist`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-risk-users-list"))
      },
      {
        exact: true,
        path: BASE_URL,
        guard: SinglePageGuard,
        component: () => <Redirect to={`${BASE_URL}/vehicles`} />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: "*",
    layout: LayoutMain,
    routes: [
      {
        exact: true,
        path: "",
        component: () => <Redirect to={`${BASE_URL}/users`} />
      },
      {
        component: () => <Redirect to="404" />
      }
    ]
  }
];

export default routes;
