import React, { FC, useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";

import clsx from "clsx";

import { Button, Collapse, ListItem, makeStyles } from "@material-ui/core";

import { ExpandMore, ExpandLess } from "@mui/icons-material";

import type { Theme } from "./../utils/util-theme";

export interface NavItemProps {
  className?: string;
  depth: number;
  href: string;
  icon?: any;
  info?: any;
  open?: boolean;
  title: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    padding: "10px 8px",
    justifyContent: "flex-start",
    alignItems: "center",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%"
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightRegular,
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  icon: {
    display: "flex",
    alignItems: "flex-start",
    marginRight: theme.spacing(1),
    width: 22,
    height: 22
  },
  title: {
    marginRight: "auto"
  },
  active: {
    color: theme.palette.primary.main,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium
    },
    "& $icon": {
      color: theme.palette.primary.main
    }
  }
}));

const NavItem: FC<NavItemProps> = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open = true,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState<boolean>(open);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </Button>
        <Collapse in={isOpen}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        exact
        style={style}
        to={href}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}>{title}</span>
        {Info && <Info />}
      </Button>
    </ListItem>
  );
};

export default NavItem;
