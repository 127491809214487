import React from "react";
import type { FC } from "react";
import { makeStyles } from "@material-ui/core";
import { Theme } from "./../utils/util-theme";
import Logo from "./logo";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: "white",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0
  },
  logo: {
    color: theme.palette.primary.main,
    width: "200px",
    transformOrigin: "50% 50%",
    transition: "transform 1s linear",
    animationName: "$zoominoutsinglefeatured",
    animationDuration: "2s",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: "ease-in-out"
  },
  "@keyframes zoominoutsinglefeatured": {
    "0%": {
      transform: "scale(1)",
      opacity: 1
    },
    "100%": {
      transform: "scale(4)",
      opacity: 0
    }
  }
}));

const SplashScreen: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Logo className={classes.logo} />
    </div>
  );
};

export default SplashScreen;
