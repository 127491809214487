import React, { FC } from "react";
import { Redirect } from "react-router-dom";
import { BASE_URL } from "../constants";
import useAuthentication from "./util-use-auth";

const SinglePageGuard: FC = ({ children }) => {
  const { business, role } = useAuthentication();

  if (role.manageOwnBusiness && business) {
    return <Redirect to={`${BASE_URL}/business/${business.uuid}`} />;
  }

  if (role.viewSingleTrip) {
    return <Redirect to={`${BASE_URL}`} />;
  }

  return <>{children}</>;
};

export default SinglePageGuard;
