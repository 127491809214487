import { useContext } from "react";

import AuthContext from "../states/state-auth";

import { Role } from "../types/role";
import { Entity } from "../types/entity";
import { Authentication } from "../types/user";

const useAuthentication = () => useContext(AuthContext);

type AuthenticatedUser = {
  user: Authentication;
  role: Role;
  business?: Entity;
};
export const useUser = (): AuthenticatedUser => {
  const { user, role, business, logout } = useAuthentication();

  if (!user || !role) {
    throw logout();
  }

  return {
    user,
    role,
    business
  };
};

export default useAuthentication;
