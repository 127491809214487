export const APP_VERSION = "1.0.0";

export const THEMES = {
  LIGHT: "LIGHT",
  ONE_DARK: "ONE_DARK",
  UNICORN: "UNICORN",
  POPPY: "POPPY"
};

export const BASE_URL = "" as const;

export const PAGE_LIST_ROWS_TO_DISPLAY = 100;
export const PAGE_LIST_ROWS_TO_DISPLAY_MOBILE = 10;
