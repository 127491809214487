export type APIErrorResponse = {
  error: string;
  message: string;
  statusCode: number;
};

export class APIError extends Error {
  constructor(public readonly response: APIErrorResponse) {
    super(response.message);
  }
}