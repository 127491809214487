import React, { Fragment } from "react";
import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import Logo from "./../components/logo";
import { THEMES, BASE_URL } from "./../constants";
import type { Theme } from "./../utils/util-theme";
import Account from "./account";
import Search from "./search";
import Settings from "./settings";
import { useUser } from "../utils/util-use-auth";

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === (THEMES.LIGHT || THEMES.POPPY)
      ? {
          boxShadow: "none",
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    minHeight: 64
  }
}));

const TopBarDashboard: FC<TopBarProps> = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const { role } = useUser();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to={BASE_URL} style={{ display: "flex" }}>
            <Logo style={{ color: "white" }} />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        {!role.manageOwnBusiness && !role.viewSingleTrip && (
          <Fragment>
            <Search />
            <Settings />
          </Fragment>
        )}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBarDashboard.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBarDashboard.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBarDashboard;
