import React, {
  createContext,
  FC,
  useEffect,
  useReducer,
  useState
} from "react";
import SplashScreen from "../components/splash-screen";
import { apiConfig } from "./../config";
import { Entity } from "./../types/entity";
import { Role } from "./../types/role";
import type { Authentication, User } from "./../types/user";
import * as firebaseAuth from "firebase/auth";
import { getFirebaseApp } from "../utils/util-firebase";
import { findBusinessOrPartnerEntity } from "../utils/util-entity";

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  avatar?: string;
  user: Authentication | null;
  role: Role | null;
  business: Entity | null;
}

interface AuthContextValue extends AuthState {
  signInWithGoogle: () => Promise<any>;
  signInWithEmail: (email: string) => Promise<void>;
  logout: () => Promise<void>;
}

type Action = {
  type: "AUTH_STATE_CHANGED";
  payload: {
    isAuthenticated: boolean;
    avatar?: string;
    user: Authentication | null;
    role: Role | null;
    business: Entity | null;
  };
};

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  role: null,
  business: null
};

async function fetchRole(token: string): Promise<Role | null> {
  const response = await fetch(`${apiConfig.baseUrl}/users/me/permissions`, {
    headers: {
      authorization: `Bearer ${token}`
    }
  });

  if (response.status !== 200) {
    return null;
  }

  return response.json();
}

async function fetchUser(token: string): Promise<User | null> {
  const response = await fetch(`${apiConfig.baseUrl}/users/me`, {
    headers: {
      authorization: `Bearer ${token}`
    }
  });

  if (response.status !== 200) {
    return null;
  }

  return response.json();
}

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case "AUTH_STATE_CHANGED": {
      const { isAuthenticated, user, role, business, avatar } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        role,
        business,
        avatar
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  signInWithGoogle: () => Promise.resolve(),
  signInWithEmail: async () => {},
  logout: () => Promise.resolve()
});

export const AuthProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const [isSigninInByEmail, setIsSigninInByEmail] = useState(false);

  // make sure firebase was initialized
  getFirebaseApp();

  const signInWithGoogle = (): Promise<any> => {
    const provider = new firebaseAuth.GoogleAuthProvider();
    return firebaseAuth.signInWithPopup(firebaseAuth.getAuth(), provider);
  };

  const logout = (): Promise<void> => {
    return firebaseAuth.signOut(firebaseAuth.getAuth());
  };

  async function signInWithEmail(email: string) {
    try {
      await firebaseAuth.sendSignInLinkToEmail(firebaseAuth.getAuth(), email, {
        url: window.location.origin,
        handleCodeInApp: true
      });
      localStorage.setItem("emailForSignIn", email);
      alert("Authentication email sent! Check your mail box");
    } catch (error) {
      alert(JSON.stringify(error));
    }
  }

  async function handleEmailSignIn(url: string) {
    if (!firebaseAuth.isSignInWithEmailLink(firebaseAuth.getAuth(), url)) {
      return;
    }

    let email = localStorage.getItem("emailForSignIn");
    if (!email) {
      email = prompt("Please provide your email for confirmation");
    }

    try {
      setIsSigninInByEmail(true);
      const { user } = await firebaseAuth.signInWithEmailLink(
        firebaseAuth.getAuth(),
        email,
        url
      );
      localStorage.removeItem("emailForSignIn");
      return user;
    } catch (error) {
      alert(JSON.stringify(error));
    } finally {
      setIsSigninInByEmail(false);
    }
  }

  useEffect(() => {
    const url = window.location.href;
    handleEmailSignIn(url);

    const unsubscribe = firebaseAuth.onAuthStateChanged(
      firebaseAuth.getAuth(),
      async (firebaseUser) => {
        if (!firebaseUser) {
          dispatch({
            type: "AUTH_STATE_CHANGED",
            payload: {
              isAuthenticated: false,
              user: null,
              role: null,
              business: null
            }
          });
        } else {
          const token = await firebaseUser.getIdToken();
          const role = await fetchRole(token);
          const user = await fetchUser(token);
          const isAuthenticated = token && Boolean(role);

          if (!isAuthenticated) {
            dispatch({
              type: "AUTH_STATE_CHANGED",
              payload: {
                avatar: undefined,
                isAuthenticated: false,
                user: null,
                role: null,
                business: null
              }
            });
            return;
          }

          const business = user.Entity.find(findBusinessOrPartnerEntity);

          dispatch({
            type: "AUTH_STATE_CHANGED",
            payload: {
              isAuthenticated,
              avatar: firebaseUser.photoURL,
              user: {
                email: firebaseUser.email!,
                name: firebaseUser.displayName || firebaseUser.email!,
                token,
                ...user
              },
              role,
              business
            }
          });
        }
      }
    );

    return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signInWithGoogle,
        signInWithEmail,
        logout
      }}
    >
      {isSigninInByEmail ? <SplashScreen /> : children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
