import { Chip } from "@material-ui/core";
import React, { FC } from "react";
import { VehicleStatus as VehicleStatusType } from "../../types/vehicle";
import { vehicleStatusColors } from "../../utils/util-theme";

export const VehicleStatus: FC<{ status: VehicleStatusType }> = ({
  status
}) => {
  const statusColor = vehicleStatusColors[status];
  return (
    <Chip
      label={status}
      size="small"
      style={{
        textTransform: "capitalize",
        color: statusColor ? statusColor[800] : "white",
        backgroundColor: statusColor ? statusColor[50] : "black"
      }}
    />
  );
};
